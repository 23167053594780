.app-style {
    z-index: 1200;
    background: #000000bd;
}
.app-style2 {
    z-index: 1200;
    background: #000000bd;
}

.app-style .modal-dialog.modal-sm {
    max-width: 100%;
    width: 360px;
}

.app-style .modal-content {
    border-radius: 0px;
    min-height: 600px;
    height: 100%;
}

.app-style .modal-body {
    padding: 0;
}


/* Only for Mobile Device | Start */

.app-style.device-xs .modal-dialog {
    max-width: 100%;
    width: 100%;
    margin: 0;
    height: 100%;
    min-height: 100%;
}

.app-style.device-xs .modal-content {
    height: 100%;
    min-height: 100%;
    border: 0px;
}


/* Only for Mobile Device | End */

.app-style-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.app-style-header {
    border-bottom: 1px solid #aaa;
}

.app-style-body {
    flex-grow: 1;
    padding: 16px;
}

.app-style-footer {
    /* border-top: 1px solid #999; */
    padding: 10px 16px;
}

.app-style-content.header-footer-fixed .app-style-body {
    overflow-y: auto;
}

.flex-grow {
    flex-grow: 1;
}

.field-title {
    font-family: "Roboto", sans-serif;
}

.chip-style {
    color: black;
    text-decoration: none !important;
    float: right;
    padding-bottom: 15px;
}

.chip-style-category {
    margin: 2px;
}

.image-item {
    padding: 0;
    width: 100%;
    height: 200px;
    border: 1px solid #dad3d4;
    margin-top: 12px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.image-item>img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.image-item>p {
    width: 100%;
    padding-left: 23%;
    vertical-align: middle;
    height: 100px;
    padding-top: 14%;
}

.image-item-btn {
    width: 48px;
    height: 48px;
    position: absolute;
    right: -1px;
    top: -1px;
    background: #bbb3b4a3;
    display: inline-block;
    text-align: center;
    border-bottom-left-radius: 15px;
}

.upload-btn {
    margin-top: 10px;
}

.price-vat-show {
    margin-top: 30px;
    background: #e9d4d47a;
    border-radius: 5px;
    padding: 8px;
}

.price-vat-show>span {
    font-size: 20px;
    font-weight: bold;
    padding: 3px;
}
/*Shop Modal*/
.shop-detail-cover{
    border-radius: 5px;
    
}
.shop-detail-cover>img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: top;
}
.shop-detail-title{
   padding-left: 30px;
    display: flex;
}
.shop-detail-title>div>h6{
    margin-top:20px;
    padding-left: 10px;
}
.shop-detail-title>div>p{

    padding-left: 10px;
}
.shop-detail-title-menu{
    margin-left: auto;
    margin-top:25px;
}