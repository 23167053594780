@media (max-width: 576px) {
    .hide-avatar {
        display: none;
    }
    .squire-image {
        margin: -26px;
        width: calc(100% + 52px) !important;
        margin-bottom: 15px;
    }
    .inbox_people{
        display: none;
    }
    .mesgs {
        width: 100%;
    }
    .inbox_msg {
    
        height: 1000px;
    }
    .msg_history {
    height: 892px;
    overflow-y: auto;
    }
    .received_withd_msg {
        width: 75%;
        padding-left: 19px;
    }
}